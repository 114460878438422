<template #default="{ errorMessages }">
    <div elevation="0">
    <v-tabs
        v-model="tab"
        centered
        grow
        active-class="font-weight-bold tab-active"
        slider-size="3"
    >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#1" :class="`tab-header   ${stClasses.st1}`" data-cy="tab-1-input">
            Datos Generales
        </v-tab>

        <v-tab href="#2" :class="`tab-header  ${stClasses.st2}`" data-cy="tab-2-input">
            Variantes
        </v-tab>

      
    </v-tabs>

    <!-- STEP 1 "Datos generales" -->
    <v-tabs-items v-model="tab" >
        <v-tab-item value="1" class="mb-10">
            <div class="mt-3">
               
                <v-row justify="center">
                    <v-col class="" >
                        <div class="bill-mark-all-cont">
                            
                            <checkboxForm
                                name="p1"
                                label="Materia Prima"
                                :valueInput="values.supplies"
                                valueCheck="Materia Prima"
                                :rules="rules.checkRule"
                                hideDetails="auto"
                                @valueChange="(v) => {values.supplies = v;  delete serverErrors[`supplies`];}"
                                :errorMessages="serverErrors[`supplies`]"
                                data-cy="supplies_materia-input"
                            ></checkboxForm>
                        </div>
                    </v-col>
                    <v-col class="" >
                        <div class="bill-mark-all-cont">
                            
                            <checkboxForm
                                name="p1"
                                label="Consumibles"
                                :valueInput="values.supplies"
                                valueCheck="Consumibles"
                                :rules="rules.checkRule"
                                :hideDetails="true"
                                @valueChange="(v) => {values.supplies = v;  delete serverErrors[`supplies`];}"
                                :errorMessages="serverErrors[`supplies`]"
                                data-cy="supplies_consumable-input"
                            ></checkboxForm>
                        </div>
                    </v-col>
                    <v-col class="" >
                        <div class="bill-mark-all-cont">
                            
                            <checkboxForm
                                name="p1"
                                label="Indirectos"
                                :valueInput="values.supplies"
                                valueCheck="Indirectos"
                                :rules="rules.checkRule"
                                :hideDetails="true"
                                @valueChange="(v) => {values.supplies = v;  delete serverErrors[`supplies`];}"
                                :errorMessages="serverErrors[`supplies`]"
                                data-cy="supplies_indirect-input"
                            ></checkboxForm>
                        </div>
                    </v-col>
                </v-row>
                <v-row data-cy="supplies-input-error" >
                    <v-col class="error--text">
                        {{serverErrors[`supplies`]}}
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Nombre"
                            :valueInput="values.name"
                            :rule="rules.required"
                            data-cy="name-input"
                            @valueChange="
                                (v) => {
                                    values.name = v;
                                    delete serverErrors[`name`];
                                }
                            "
                            :errorMessages="serverErrors[`name`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Descripción"
                            :valueInput="values.description"
                            :rule="rules.required"
                            data-cy="description-input"
                            @valueChange="
                                (v) => {
                                    values.description = v;
                                    delete serverErrors[`description`];
                                }
                            "
                            :errorMessages="serverErrors[`description`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Marca"
                            :valueInput="values.brand"
                            :rule="rules.required"
                            data-cy="brand-input"
                            @valueChange="
                                (v) => {
                                    values.brand = v;
                                    delete serverErrors[`brand`];
                                }
                            "
                            :errorMessages="serverErrors[`brand`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            name="p1"
                            label="Unidad"
                            :loading="loadingUnits"
                            :valueInput="values.unit_id"
                            :rule="rules.required"
                            :items="serverItems.units"
                            :errorMessages="serverErrors.unit_id"
                            @valueChange="(v) => {values.unit_id = v; delete serverErrors.unit_id;delete serverErrors.catalog}"
                            data-cy="unit_id-input"
                            :disabled="edit"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Proveedores</p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <autocompleteMultipleForm
                        name="p1"
                        label="Proveedores"
                        :valueInput="values.suppliers"
                        :rule="rules.checkRule"
                        :items="serverItems.suppliers"
                        :loading="loadingSuppliers"
                        @valueChange="(v) => {values.suppliers = v; delete serverErrors.commodity_suppliers}"
                        :errorMessages="serverErrors.commodity_suppliers"
                        :selectAll="true"
                        data-cy="suppliers-input"
                        ></autocompleteMultipleForm>
                    </v-col>
                </v-row>
                



                
                
            </div>
        </v-tab-item>

        <!--STEP 2  "Documentación" -->
        <v-tab-item value="2">
            <div class="mt-3">
                <v-form ref="formVariant" :key="updateFormKey">
                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm
                                name="p2"
                                label="Tamaño o medidas"
                                :valueInput="variantForm.size"
                                :rule="rules.required"
                                data-cy="variant-form-size-input"
                                @valueChange="
                                    (v) => {
                                        variantForm.size = v;
                                    }
                                "
                                :errorMessages="serverErrors[`size`]"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm
                                name="p2"
                                label="Color"
                                :valueInput="variantForm.color"
                                :rule="rules.required"
                                data-cy="variant-form-color-input"
                                @valueChange="
                                    (v) => {
                                        variantForm.color = v;
                                    }
                                "
                                :errorMessages="serverErrors[`color`]"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-2 mb-5">
                            <textFieldForm
                                name="p2"
                                @valueChange="(v) => {variantForm.price = v}" 
                                :valueInput="variantForm.price" 
                                :rule="rules.cost" 
                                label="Precio"
                                :errorMessages="serverErrors.price"
                                :prefix="variantForm.price == '' ? '' : '$'"
                                data-cy="variant-form-price-input"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="text-right">
                            <secondary-button data-cy="accept-variant-btn" :props="addVariantBtnData" class=" mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        </v-col>
                    </v-row>


                </v-form>

                <!-- table -->
                <v-row>
                    <v-col class="py-3">
                        <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.variants">
                            
                            <template v-slot:item.size="props" >

                                <div class="table-item">
                                    <textFieldForm 
                                        @valueChange="(v) => {props.item.size = v; delete serverErrors.services}" 
                                        :valueInput="props.item.size" 
                                        :rule="[...rules.required]" 
                                        :disabled="props.item.disabled"
                                        data-cy="table-size-input"
                                    ></textFieldForm>
                                </div>
                            </template>

                            <template v-slot:item.color="props" >

                                <div class="table-item">
                                    <textFieldForm 
                                        @valueChange="(v) => {props.item.color = v; delete serverErrors.services}" 
                                        :valueInput="props.item.color" 
                                        :rule="[...rules.required]" 
                                        
                                        data-cy="table-color-input"
                                    ></textFieldForm>
                                </div>
                            </template>

                            <template v-slot:item.price="props" >

                                <div class="table-item">
                                    <textFieldForm 
                                        @valueChange="(v) => {props.item.price = v; delete serverErrors.services}" 
                                        :valueInput="props.item.price" 
                                        :rule="[...rules.cost]" 
                                        
                                        data-cy="table-price-input"
                                    ></textFieldForm>
                                </div>
                            </template>
                            
                        </table-items>
                        
                    </v-col>
                    
                </v-row>
               
                <span :key="errorVariantKey" class="red--text caption" v-if="serverErrors[`commodity_variants`]">{{serverErrors[`commodity_variants`]}}</span>
            
            
            </div>
        </v-tab-item>

        
       
    </v-tabs-items>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
/* eslint-disable */
export default {
    props: ["values", "stepIn", "stepClasses", "serverErrors", "edit"],
   
    model: {
        event: "tabChanged",
    },
    watch: {
        [`serverErrors`]: function (v) {
            console.log("updaate")
           this.errorVariantKey = this.errorVariantKey + 1;
        },
       
    },
    computed: {
        tab: {
            get: function () {
                return this.stepIn;
            },
            set: function (value) {
                //console.log(value)
                this.setRules(value);
                this.$emit("tabChanged", value);
                //this.tab = stepIn;
            },
        },

        stClasses: {
            get: function () {
                console.log(this.stepClasses, "form step classes")
                return this.stepClasses;
            },
            set: function (value) {
                //console.log(value)
                //  this.$emit('tabChanged', value)
                //this.tab = stepIn;
            },
        },

       
    },
    data() {
        return {
            errorVariantKey: 7786,
            updateFormKey:1123,
            variantForm:{
                size: "",
                color: "",
                price: "",
            },
            e1: 1,
            editMode:false,
            controlKey: 50,
            show4: false,
            loadingUnits:false,
            loadingSuppliers: false,
            addVariantBtnData: {
                text: "Agregar",
                icon: "",
                to: "",
                block:false,
                click: () => {this.addVariant()}
            },
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
                checkRule: [
                    v => v.length >=1 || "Seleccione al menos uno"
                ],
                email: [
                    (v) => {
                        if(v == null || v == "")
                            return true;

                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
                product_value: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        // if(v === null || v.toString().trim() === '')
                        //     return 'El campo es requerido'
                        return true;
                    }
                ],
                onlyNumbers: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
               
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
            },
            
            
            
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'primary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-project-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Tamaño',
						align: 'left break-words',
						value: 'size',
                        width:"50%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Color',
						align: 'center',
						value: 'color',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Precio',
						align: 'center',
						value: 'price',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},

					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '12%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            serverItems: {
                suppliers: [],
                units: []
            },
           
        };
    },
    mounted() {
      this.getUnits();
      this.getSuppliers();
      this.loadVariants();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        getUnits()
        {
            this.loadingUnits = true;
            this.$api.catalog.getUnits()
                .then((resp) => {
                    this.serverItems.units = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUnits = false;
                })
            ;
        },

        getSuppliers()
        {
            this.loadingSuppliers = true;
            this.$api.supplier.getSelect()
                .then((resp) => {
                    this.serverItems.suppliers = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSuppliers = false;
                })
            ;
        },



        loadVariants()
        {
            const idLoc = uuidv4();
            this.values.variants = this.values.variants.map(item => {
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.id??idLoc, name: item.size, index: 99},
                    ...item,
                    localId: item.id??idLoc
                }
            })

            
        },

        addVariant(){
			if(this.$refs.formVariant.validate()){
                const inputValues = JSON.parse(JSON.stringify(this.variantForm));
                let item = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: uuidv4(), name: inputValues.size, index: 99},
                    size: inputValues.size,
                    color: inputValues.color,
                    price: inputValues.price,
                    localId:  uuidv4()
                }
                this.values.variants.push(item);
                this.$refs.formVariant.reset();
                this.updateFormKey = this.updateFormKey + 1;
                this.variantForm.price = "";
                delete this.serverErrors.commodity_variants
            }
           
        },

        deleteItem(item)
        {
            // this.values.products.splice(item.index, 1);
            let index = this.values.variants.findIndex(prod => prod.localId == item.id);

            console.log(index, item);
            this.values.variants.splice(index, 1);
        },

      
      
        /**
         * Set form rules by step
         */
        setRules(step) {
            console.log(step);
            // this.rules = {};
            // if (step == 1) {
            //     this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     };
            //     return;
            // } else if (step == 2) {
            //     this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     };
            //     return;
            // }

            // this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     ...this.rulesThree,
            // };
        },


       
     
      
    },
};
</script>

<style lang="scss">
.tab {
  &-header {
    font-size: 12px !important;
    color: var(--v-normalText-lighten4) !important;
  }

  &-active {
    color: var(--v-normalText-base) !important;
  }

  &-error {
    color: var(--v-warning-base) !important;
  }
}
// .less-margin {
//   margin-bottom: -20px;
// }
</style>